import { Routes, Route } from "react-router-dom";
import Userhome from "./userInterface/Userhome";
import Userreviewingpage from "./userInterface/UserReviewing";
import SignupPage from "./auth/signup";
import LoginPage from "./auth/login";
import Adminhome from "./AdminInterface/Adminhome";
import AdminviewingReviews from "./AdminInterface/AdminviewingReviews";
import AddCompany from "./AdminInterface/Addcompany";

// import TestAdd from "./AdminInterface/AddcompanyTest";
import About from "./components/about";
import AllCompanies from "./userInterface/allComapniesDisplay";
import BussinessEnquiry from "./userInterface/bussinessEnquiry";
import AdminAbout from "./AdminInterface/Adminabout";
// import InfinityLoop from "./AdminInterface/infinityscroll";

function App() {
  return (
    <div className="">
      <Routes>
        <Route exact path="/" element={<Userhome />} />
        <Route exact path="/Userreview" element={<Userreviewingpage />} />
        <Route exact path="/signup" element={<SignupPage />} />
        <Route exact path="/login" element={<LoginPage />} />

        <Route exact path="/Adminhome" element={<Adminhome />} />
        <Route exact path="/Reviews" element={<AdminviewingReviews />} />
        <Route exact path="/Addcompany" element={<AddCompany />} />
        {/* <Route exact path="/Addcompanytest" element={<TestAdd />} /> */}
        <Route exact path="/about" element={<About />} />
        <Route exact path="/allcompanies" element={<AllCompanies />} />
        <Route exact path="/businessenquiry" element={<BussinessEnquiry />} />
        <Route exact path="/about-admin" element={<AdminAbout />}/>
      </Routes>
    </div>
  );
}

export default App;
