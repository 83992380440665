import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import UserNavbar from "./usernavbar";
import Footer from "../components/Footer";
import apiList from "../lib/apiList";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Pagination } from "antd";
import allCompanieslogo from "../aseets/allcompanies.png";
import Loader from "../components/loader";

const testimonialLogo = require("../aseets/team.png");

const Testimonials = [
  {
    description: `As a proud partner of Review Master, our company values the commitment to transparency and employee feedback that this platform facilitates. We believe in the power of honest reviews to foster a positive work environment. Review Master's unbiased approach allows our employees to share their experiences openly, contributing to a culture of continuous improvement. This partnership has been instrumental in strengthening our employer brand and showcasing our dedication to employee satisfaction`,
    givenBy: "JackS18",
    comments: 0,
  },
  {
    description:
      "Review Master has become an integral part of our commitment to employee engagement and satisfaction. Being associated with this platform has allowed us to demonstrate our dedication to transparency and accountability. The unbiased reviews on Review Master provide valuable insights into our workplace culture, helping us identify areas for improvement and reinforcing our commitment to fostering a positive work environment. We are proud to be affiliated with a platform that values honesty and openness in employee feedback",
    givenBy: "Famous People",
    comments: 0,
  },
  {
    description:
      "As a company partnered with Review Master, we appreciate the platform's dedication to providing an unbiased space for employees to share their thoughts. This partnership has not only strengthened our relationship with our workforce but has also enhanced our reputation as an employer who values transparency and open communication. Review Master's commitment to impartiality aligns seamlessly with our values, and we are grateful for the opportunity to showcase our commitment to employee satisfaction through this valuable platform.",
    givenBy: "FullAstro",
    comments: 0,
  },
];

const Testimonial = ({ description, givenBy, comments }) => (
  <div className="article ">
    <div className="m-3 text-center ">
      <img
        src={testimonialLogo}
        width={80}
        alt="testimonialLogo"
        style={{ borderRadius: "100%" }}
      ></img>{" "}
    </div>
    <div>
      <span className="mb-3">{description}</span>{" "}
      <p className="mt-1">
        by : <span style={{ fontWeight: "500" }}> {givenBy}</span>
      </p>
      <span>{comments} comments</span>
    </div>
  </div>
);
const categoryArray = [
  "IT Services and Consulting",
  "Food Processing",
  "Automobile",
  "Electronics",
  "Banking/Finance",
  "Travel",
  "Books/Printing and Publishing",
  "Media and Entertainment",
  "Educational institutions",
  "Small-Business - Malls - Stores",
  "Home and Appliances",
  "Real Estate / Construction",
];

const AllCompanies = () => {
  const [companies, setComapnies] = useState([]);
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [companyNameInput, setCompanyNameInput] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 12;
  const [category, setCategory] = useState("");
  const [loading, setLoading] = useState(true)

  const navigate = useNavigate();
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  const startIdx = (currentPage - 1) * pageSize;
  const endIdx = startIdx + pageSize;
  const displayedCompanies = filteredCompanies.slice(startIdx, endIdx);

  const allCompanies = async (req, res) => {
    try {
      setLoading(true)
      const response = await axios.get(apiList.allCompanies);
      setComapnies(response.data);
      setFilteredCompanies(response.data);
      setLoading(false)
    } catch (err) {
      console.error("Error searching for companies:", err);
    }
  };

  const handleCategoryClick = (category) => {
    // Set the selected category and filter the companies based on it
    setSelectedCategory(category);
    // Assuming you have a list of all companies stored in some variable (allCompanies)
    const companiesFilteredByCategory = companies.filter(
      (company) => company.category.toLowerCase() === category.toLowerCase()
    );
    setFilteredCompanies(companiesFilteredByCategory);
  };

  const handleSearch = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    const filtered = companies.filter((company) =>
      company.companyName.toLowerCase().includes(term.toLowerCase())
    );
    setFilteredCompanies(filtered);
  };

  useEffect(() => {
    allCompanies();
    setSearchTerm("");
  }, []);
  const GotoReviewComponent = (companyName) => {
    navigate("/Userreview", { state: { companyName: companyName } });
  };


  
  
  return (
    <div>
      <UserNavbar />
    {loading?<Loader/> : (
      <div>
  
      <div
        className="container d-flex flex-column flex-sm-row"
        style={{
          display: "",
          justifyContent: "space-between",
          marginTop: "100px",
        }}
      >
        <input
          type="search"
          placeholder="Search for brands ..."
          className={"categories_search form-control"}
          value={searchTerm}
          onChange={handleSearch}
        />{" "}
        <select
          id="category"
          className="form-control browser-default custom-select  mt-2 mt-sm-0 "
          value={category}
          style={{ width: "auto" }}
          onChange={(e) => handleCategoryClick(e.target.value)}
        >
          <option hidden selected>
            Choose Category
          </option>
          {categoryArray.map((categoryOption) => (
            <option key={categoryOption} value={categoryOption}>
              {categoryOption}
            </option>
          ))}
        </select>
      </div>
      <div className={filteredCompanies.length > 0 ? "reviews container" : ""}>
        {filteredCompanies.length === 0 ? (
          <div className="container mt-3  text-center">
            <h6>
              Oops! We couldn't find your company , please double-check the
              spelling or provide details you would wish to{" "}
            </h6>

            <h6>
              We will get back the company as soon as possible. Thank you !!
            </h6>
            {/* <form onSubmit={handleSubmit}>
        <label>
          Company Name:
          <input type="text" value={companyNameInput} onChange={handleInputChange} />
        </label>
        <button type="submit">Submit</button>
            </form> */}
            <div className="contact-form mt-3">
              <form id="contactForm">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label className="d-flex align-self-start">
                        Company Name <span>*</span>
                      </label>
                      <input
                        type="text"
                        name="name"
                        id="name"
                        className="form-control"
                        required
                        data-error="Please Enter Your Name"
                        placeholder="Name"
                      ></input>
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-12 text-center">
                    <button
                      type="submit"
                      className="default-btn btn-bg-two border-radius-50"
                    >
                      Send Message <i className="bx bx-chevron-right"></i>
                    </button>
                    <div id="msgSubmit" className="h3 text-center hidden"></div>
                    <div className="clearfix"></div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        ) : (
          displayedCompanies.map((review, index) => (
            <div
              className="review"
              key={index}
              onClick={() => GotoReviewComponent(review.companyName)}
            >
              <div className="logo text-center">
                <img
                  src={review.logoUrl}
                  width={54}
                  style={{ borderRadius:"5px" }}
                  height={54}
                  alt="logo"
                ></img>
              </div>
              <div className="name mt-1">{review.companyName}</div>
              <div className="rating">Rating : {review.averageRating} / 5</div>
              <div>
                {" "}
                <i className="ri-star-fill star ratingStars"></i>
                <i className="ri-star-fill star ratingStars ml-1"></i>
                <i className="ri-star-fill star ratingStars ml-1"></i>
                <i className="ri-star-fill star ratingStars ml-1"></i>
                <i className="ri-star-fill star ratingStars ml-1"></i>
              </div>

              <div
                className="my-2 text-center"
                style={{
                  border: "1px solid black",
                  padding: "7px 10px",
                  borderRadius: "10px",
                }}
              >
                Read Complete Review
              </div>
            </div>
          ))
        )}
      </div>
      <div
        className="container mb-4 "
        style={{ display: "flex", justifyContent: "end" }}
      >
        <span className="d-md-none">
          See more reviews <i className="ri-arrow-right-line"></i>
        </span>
      </div>
      <Pagination
        current={currentPage}
        onChange={handlePageChange}
        defaultPageSize={pageSize}
        className="my-3"
        total={companies ? companies.length : 0}
        style={{ margin: "auto", textAlign: "center" }}
      />
      <section className="welcome-bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <h2>
                <span>Welcome to Review Master</span>- Your Gateway to Workplace
                Insights!
              </h2>

              <p>
                {" "}
                We believe in the power of transparency and information-sharing
                to empower professionals in making informed career decisions.
                Our platform serves as a trusted space where employees can
                openly share their experiences and insights about the companies
                they work for, providing valuable perspectives for jobseekers to
                make well-informed career choices.
              </p>
              <p>
                Discover authentic reviews directly from current and former
                employees. Gain valuable insights into company cultures, work
                environments, and leadership styles to make informed decisions
                about your next career move..
              </p>
              <p>
                Review Master thrives on community participation. Contribute to
                the collective knowledge by sharing your own experiences, and
                help others make informed career decisions. Together, we're
                building a community that values transparency and supports
                professionals in their career journeys.
              </p>
              <p>
                Embark on a journey of informed decision-making with Review
                Master. Whether you're a seasoned professional considering a
                career change or a recent graduate starting your job search, our
                platform is here to guide you. Empower your career choices by
                leveraging the collective knowledge of our vibrant community.
              </p>
              <h2>
               
                  {" "}
                  <span>  Where Transparency Meets Opportunity!</span>{" "}
                
                  </h2>
            </div>
            <div className="col-lg-6 profilepic">
              {/* <img src={allCompanies} className="img-fluid" alt=""></img> */}
              <img src={allCompanieslogo} className="img-fluid" alt="img"></img>
            </div>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="articles">
          <h3 className="mb-4">What our brands are saying about us</h3>
          {Testimonials.map((article, index) => (
            <Testimonial key={index} {...article} />
          ))}
        </div>
        <div className="d-flex justify-content-center"></div>
      </div>
        <Footer />
        </div>
        )}
    </div>
  );
};

export default AllCompanies;
