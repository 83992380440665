

import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

import Footer from "../components/Footer";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import apiList from "../lib/apiList";
import { ToastContainer, toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { jwtDecode } from "jwt-decode";
import { Pagination } from "antd";
import Adminnav from "./AdminNav";
import Loader from "../components/loader";


const profileLogo = require("../aseets/profile.png");
const dummyLogo = require("../aseets/user.png");
const handdownImg = require("../aseets/backhand-down.png");

const Userreviewingpage = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const companyName = state.companyName;

  const [companyDetails, setCompanyDetails] = useState({});
  const [companyReviews, setCompanyReviews] = useState([]);
  const [latestReview, setLatestReview] = useState({});
  const [latestInternal, setLatestInternal] = useState({});
  const [loggedInUser, setLoggedInUser] = useState({});
  const [rating, setRating] = useState(0);
  const [review, setReview] = useState("");
  const [loading, setLoading] = useState(true);

  // const [internalFieldsArray, setInternalFieldsArray] = useState([]);
  const [avgInternal, setAvgInternal] = useState({});
  const [allUsers, setAllUsers] = useState([]);

  const [likes, setLikes] = useState([]);

  const pageSize = 8;
  const [currentPage, setCurrentPage] = useState(1);
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  const startIdx = (currentPage - 1) * pageSize;
  const endIdx = startIdx + pageSize;
  const displayedCompanies = companyReviews.slice(startIdx, endIdx);




  const internalFieldsArray = [
    "Work Culture",
    "Work Life Integration",
    "Professional Satisfaction",
    "Career Development",
    "Employment Security",
    "Pay and Benefits",
    "Performance Appraisal",
    "Team Engagement",
  ];
  const getAllUSers = async (req, res) => {
    try {
      const response = await axios.get(apiList.allusers);
      setAllUsers(response.data);
    } catch (err) {
      console.log("Error searching for company details :", err);
    }
  };
  const getCompanyDetails = async (req, res) => {
    try {
      setLoading(true)
      if (companyName) {
        const response = await axios.get(
          `${apiList.individualCompany}/${companyName}`
        );
        setCompanyDetails(response.data);
        setAvgInternal(response.data.internalRatings);
        setLoading(false)
      }
    } catch (err) {
      console.log("Error searching for company details :", err);
    }
  };
  const token = localStorage.getItem("jwtToken");
  const getToken = () => {
    return localStorage.getItem("jwtToken");
  };

  const removeToken = () => {
    localStorage.removeItem("jwtToken");
  };

  const isTokenExpired = () => {
    const token = getToken();
    if (!token) {
      return false;
    }

    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000; // convert milliseconds to seconds

      return decodedToken.exp < currentTime;
    } catch (error) {
      console.error("Error decoding token:", error);
      return true; // treat decoding errors as expired
    }
  };

  const getCompanyReviews = async (req, res) => {
    try {
      setLoading(true)
      if (companyName) {
        const response = await axios.get(
          `${apiList.companyReviews}/${companyName}`
        );
        setCompanyReviews(response.data);
        setLatestReview(response.data[0]);
        setLoading(false);
      }
    } catch (err) {
      console.log("Error searching for company details :", err);
    }
  };
 

  const getUserLoginDetails = async (req, res) => {
    try {
      const response = await axios.get(apiList.userDetails, {
        headers: {
          token: token,
        },
      });
      setLoggedInUser(response.data);
    } catch (err) {
      console.log("Error searching for user details :", err);
    }
  };

  const handleDeleteReview = async (reviewId) => {

    try {
      const response = await axios.delete(`${apiList.deleteReview}/${companyName}/${reviewId}`);

      if (response.status === 200) {
        getCompanyReviews();
        // Optionally, update the UI or perform other actions after successful deletion
      } else {
        console.error('Error deleting review:', response.data.error);
        // Handle errors or display an error message to the user
      }
    } catch (error) {
      console.error('Error:', error);
      // Handle network errors or other unexpected issues
    }
  };
  useEffect(() => {
    getAllUSers();
    getCompanyDetails();
    getCompanyReviews();
    getUserLoginDetails();
    if (token === null) {
      setLoggedInUser("");
    }
  }, []);


 



  return (
    <div>
      <Adminnav />

      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        transition={Zoom}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    {loading?<Loader/>:(
      <div>
      {companyDetails && (
        <div className="container" style={{ marginTop: "90px" }}>
          <div className="">
            <h5>Home > Reviews</h5>
            <div className="row">
              <div className="col-md-4 d-flex align-items-center justify-content-center">
                <div className="text-center userreview_logoContainer">
                  <h6>Review on {companyDetails.companyName}</h6>
                  <img
                    src={companyDetails.logoUrl}
                    className="userreview-logo"
                    alt="companylogo"
                  ></img>

                </div>
              </div>

              <div className="col-md-8">
                <div className="mt-3 mt-md-0">
                  <div className="analysisContainer">
                    {/* <h4 className="headingBox ">Review Master Score</h4> */}

                    <div className="d-flex flex-column flex-lg-row mx-2">

                    </div>
                    <div className="mb-2  ">

                      {avgInternal && (
                        <div className="analysisContainer">
                          {/* <h4 className="headingBox ">Review Master Score</h4> */}
                          <div className="rating-section">
                            <div className="overall-rating">
                              <span className="rating-label">
                                Overall Rating ( {companyReviews.length} Reviews
                                )
                              </span>
                              <span className="rating-value averageContainer ">
                                ★ {companyDetails.averageRating}
                              </span>
                            </div>
                            <div className="category-ratings">
                              <div className="category-rating">
                                <span className="rating-label">
                                  {internalFieldsArray[4]}
                                </span>
                                <span className="rating-value">
                                  {avgInternal.avgEmploymentSecurity}★
                                </span>
                              </div>
                              <div className="category-rating">
                                <span className="rating-label">
                                  {internalFieldsArray[6]}
                                </span>
                                <span className="rating-value">
                                  {avgInternal.avgPerformanceAppraisal}★
                                </span>
                              </div>
                              <div className="category-rating">
                                <span className="rating-label">
                                  {internalFieldsArray[5]}
                                </span>
                                <span className="rating-value">
                                  {avgInternal.avgPayandBenefits}★
                                </span>
                              </div>
                              <div className="category-rating">
                                <span className="rating-label">
                                  {internalFieldsArray[3]}
                                </span>
                                <span className="rating-value">
                                  {avgInternal.avgCareerDevelopment}★
                                </span>
                              </div>
                              <div className="category-rating">
                                <span className="rating-label">
                                  {internalFieldsArray[2]}
                                </span>
                                <span className="rating-value">
                                  {avgInternal.avgProfessionalSatisfaction}★
                                </span>
                              </div>
                              <div className="category-rating">
                                <span className="rating-label">
                                  {internalFieldsArray[7]}
                                </span>
                                <span className="rating-value">
                                  {avgInternal.avgTeamEngagement}★
                                </span>
                              </div>
                              <div className="category-rating">
                                <span className="rating-label">
                                  {internalFieldsArray[0]}
                                </span>
                                <span className="rating-value">
                                  {avgInternal.avgWorkCulture}★
                                </span>
                              </div>
                              <div className="category-rating">
                                <span className="rating-label">
                                  {internalFieldsArray[1]}
                                </span>
                                <span className="rating-value">
                                  {avgInternal.avgWorkLifeIntegration}★
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="text-start mt-4">
                        <h5>Description : </h5>
                        <p className="descriptionText">
                          {companyDetails.description}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="" style={{ marginTop: "40px" }}>
                  <h5>Company Details</h5>
                  <ul className="aboutContainer">
                    <li className="aboutItem">
                      <p className="aboutItem_Label">Founded In</p>
                      <p className="textItem__val aboutItem__value">
                        {companyDetails.foundedIn}
                      </p>
                    </li>
                    <li className="aboutItem">
                      <p className="aboutItem_Label">Ownership</p>
                      <p className="textItem__val aboutItem__value">
                        {companyDetails.ownership}
                      </p>
                    </li>
                    <li className="aboutItem">
                      <p className="aboutItem_Label">Employee count</p>
                      <p className="textItem__val aboutItem__value">
                        {companyDetails.numberOfEmployees}
                      </p>
                    </li>

                    <li className="aboutItem">
                      <p className="aboutItem_Label">Headquarters</p>
                      <p className="textItem__val aboutItem__value">
                        {companyDetails.headquaters}
                      </p>
                    </li>

                    <li className="aboutItem">
                      <p className="aboutItem_Label">CEO</p>
                      <p className="textItem__val aboutItem__value">
                        {companyDetails.CEO}
                      </p>
                    </li>
                    <li className="aboutItem">
                      <p className="aboutItem_Label">Founders</p>
                      <p className="textItem__val aboutItem__value">
                        {companyDetails.founders}
                      </p>
                    </li>
                    <li className="aboutItem">
                      <p className="aboutItem_Label">Company Email ID</p>
                      <p className="textItem__val aboutItem__value">
                        {companyDetails.companyEmail}
                      </p>
                    </li>
                    <li className="aboutItem">
                      <p className="aboutItem_Label">Company Contact no.</p>
                      <p className="textItem__val aboutItem__value">
                        {companyDetails.contactNo}
                      </p>
                    </li>
                    <li className="aboutItem">
                      <p className="aboutItem_Label">Social Media Presence</p>
                      <p className="textItem__val aboutItem__value">
                        {companyDetails.socialMediaPresence}
                      </p>
                    </li>

                    <li className="aboutItem">
                      <p className="aboutItem_Label">Website</p>
                      <p className="textItem__val aboutItem__value">
                        {companyDetails.website}
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="container mt-3">
        <h5>Reviews On {companyDetails.companyName}</h5>

        <div className="row">
          {displayedCompanies.map((latestReview, index) => (
            <div className="col-lg-4 col-xl-3 col-sm-6">
              <div
                className=" userreview-containeredit"
                style={{ alignItems: "center", width: "" }}
              >
                <div className="">
                  <div
                    className=" text-center "
                    style={{ padding: "20px 10px" }}
                  >
                    <div>
                      <img src={dummyLogo} className="" alt="companylogo"></img>
                      <p className="mb-1">{latestReview.name}</p>
                      <span>{latestReview.place}</span>
                      <p className="mb-1">{latestReview.designation}</p>

                    </div>
                  </div>
                </div>

                <div className=" ">
                  <div className="mt-3 mt-md-0">
                    <div className=" ">
                      <button className="year">
                        {" "}
                        <div className="d-md-flex  align-items-center ">
                          {" "}
                          <div className="  averageContainer2 mt-2">
                            <span> {latestReview.rating}/5 </span>{" "}
                          </div>
                          <div
                            className={` ratingStars-${latestReview.rating}`}
                          >
                            {[1, 2, 3, 4, 5].map((star) => (
                              <i key={star} className={`ri-star-fill star`}></i>
                            ))}
                          </div>
                        </div>
                      </button>
                      <div className="content-options">
                        {latestReview.categoryFields && Object.entries(latestReview.categoryFields).map(
                          ([key, value]) => (
                            <div key={key}>
                              <span>
                                <span style={{ color: "#f7a200" }}>★</span>{" "}
                                <span className="mr-2"> {value} </span> {key}
                              </span>
                            </div>
                          )
                        )}
                      </div>
                      <div
                        className="mt-2 mb-2"
                        style={{ height: "90px", overflow: "scroll" }}
                      >
                        <span>
                          {" "}
                          <span style={{ fontWeight: "500" }}>
                            Review :
                          </span>{" "}
                          {latestReview.review}
                        </span>
                      </div>
                      <div>
                        <span className="">3 days ago</span>
                      </div>

                      {/* Map categoryFields here */}
                    </div>
                    <div className="mt-2 userreview-usefulcontainer">
                      <div className="d-flex justify-content-between">
                        <div
                          className=""
                          style={{
                            // border: "1px solid #ccc",
                            borderRadius: "5px",
                            // backgroundColor: "rgba(0,0,0,0.05)",
                          }}
                        >
                          {(
                            <span className="px-1">
                              <i
                                class="ri-thumb-up-line px-1"
                                style={{
                                  fontSize: "22px",
                                  color: "#0000008f",
                                }}
                              ></i>{" "}
                              {
                                latestReview.liked.filter(
                                  (like) => like.likes === true
                                ).length
                              }{" "}
                            </span>
                          )}
                          <i
                            class="ri-thumb-down-line px-2"
                            style={{
                              fontSize: "22px",
                              color: "#0000008f",
                              borderLeft: "1px solid #ccc",
                            }}
                          ></i>
                          {
                            latestReview.liked.filter(
                              (like) => like.likes === false
                            ).length
                          }{" "}
                        </div>

                        <div className="d-flex align-items-center">
                          {(
                            <span className="px-1">
                              <i
                                class="fa-solid fa-flag mx-2"
                                style={{
                                  fontSize: "17px",
                                  color: "#0000008f",
                                }}
                              // onClick={() =>
                              //   handleReport(latestReview._id, false)
                              // }
                              ></i>
                              Reported{" "}{
                                latestReview.reported.filter(
                                  (like) => like.reports === true
                                ).length
                              }{" "}
                            </span>
                          )}
                          {/* <i class="fa-regular fa-flag mx-2" onClick={() => handleReport(latestReview._id, true)}></i>
                          <i class="fa-solid fa-flag mx-2"  onClick={() => handleReport(latestReview._id, false)}></i>Report */}
                        </div>
                      </div>
                    </div>

                    <div className="mt-2 userreview-usefulcontainer py-1" onClick={() =>
                      handleDeleteReview(latestReview._id, true)
                    } style={{ borderTop: "solid 1px #ccc" }}>
                      <div className="d-flex justify-content-center">
                        <div>
                          <i class="ri-delete-bin-2-line"></i> Delete
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <Pagination
          current={currentPage}
          onChange={handlePageChange}
          defaultPageSize={pageSize}
          className="my-3"
          total={companyReviews ? companyReviews.length : 0}
          style={{ margin: "auto", textAlign: "center" }}
        />
        </div>
        

        <Footer />
        </div>
        )}
    </div>
  );
};

export default Userreviewingpage;
