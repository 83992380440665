import React, { useEffect, useState, useRef} from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast,Zoom, ToastContainer } from "react-toastify";
import "bootstrap/dist/css/bootstrap.min.css";
import "remixicon/fonts/remixicon.css";
import UserNavbar from "../userInterface/usernavbar";
import Footer from "./Footer";
import aboutuslogo from "../aseets/mmm.jpg";
import review2 from "../aseets/review2.png";
import bgcontact from '../aseets/contact-bg.webp'
import Adminnav from "../AdminInterface/AdminNav";
import Cookies from "js-cookie";

const About = () => {
  
  const loginType = Cookies.get('loginType');
  const [formData, setFormData] = useState({
    "Contact Name": "",
    "Contact email": "",
    "Contact number": "",
    "Contact subject": "",
    "Contact Message": "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const dataUrl =
        "https://sheet.best/api/sheets/d7be461e-8e6f-40fc-a543-fcd845a4b779";

      const response = await axios.post(dataUrl, formData, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      setFormData({
        "Contact Name": "",
        "Contact email": "",
        "Contact number": "",
        "Contact subject": "",
        "Contact Message": ""
      });

      toast.success("Thank you for reaching us ! We will respond shortly");
      // Optionally, you can handle the response or perform other actions.
    } catch (error) {
      console.error("Error:", error);
      // Handle the error as needed.
    }
  };

  return (

    <div>
      {loginType === 'admin' ? <Adminnav /> : <UserNavbar />}
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        transition={Zoom}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="" style={{ marginTop: "100px" }}>
        <div className="about-area ptb-70">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="about-play">
                  <img
                    src={aboutuslogo}
                    style={{ borderRadius: "20px" }}
                    className="w-75"
                    alt="About Images"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="about-content ml-25">
                  <div className="section-title">
                    <h2>About US</h2>
                    <p>
                      Welcome to Review Master, where opinions meet insights,
                      and choices become clear! Our platform is more than just a
                      review website; it's a community of discerning individuals
                      who understand the importance of informed decisions.
                    </p>
                  </div>

                  <div className="row">
                    <div className="col-lg-12 col-md-12 mt-3">
                      <ul className="about-list text-start p-0">
                        <li>
                          <i className="fa-solid fa-circle-check"></i>
                          Community-Powered Insights
                        </li>
                        <li>
                          <i className="fa-solid fa-circle-check"></i>Unbiased
                          Reviews
                        </li>
                        <li>
                          <i className="fa-solid fa-circle-check"></i>
                          Transparency is Key
                        </li>
                        <li>
                          <i className="fa-solid fa-circle-check"></i>Smart
                          Recommendations Workforce Happiness
                        </li>
                        <li>
                          <i className="fa-solid fa-circle-check"></i>Engage and
                          Connect
                        </li>
                        <li>
                          <i className="fa-solid fa-circle-check"></i>Your Voice
                          Matters
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="about-content ml-25">
                  <p className="about-content-text">
                    We are on a mission to simplify your choices. We believe
                    that every decision you make, whether it's choosing a
                    product, service, or experience, should be well-informed and
                    stress-free. That's why we've created a space where
                    authentic opinions and thoughtful reviews converge to guide
                    you through the myriad of options.We pride ourselves on
                    providing unbiased, honest reviews. Our community consists
                    of real users sharing real experiences, ensuring you get the
                    most accurate picture possible.Transparency is at the core
                    of our values. We disclose how reviews are collected, and we
                    constantly strive to maintain the integrity of our platform.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="choose-area pt-70 pb-70">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 d-flex justify-content-center">
                <div className="choose-content mr-20">
                  <div className="section-title">
                    <h2>Vision</h2>
                    <p>
                      To Become One Among the Leading Man Power Sourcing Firms.
                      To Attract and Retain the Talented and Skilled Work Force
                      by Facilitating the Career Growth.
                    </p>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-6">
                      <div className="choose-content-card">
                        <div className="content">
                          {/* <i className="flaticon-practice"></i> */}
                          <i className="fa-solid fa-hands-praying"></i>
                          <h3>Experience</h3>
                        </div>
                        <p>Employees's opinion</p>
                      </div>
                    </div>
                    <div className="col-lg-6 col-6">
                      <div className="choose-content-card">
                        <div className="content">
                          {/* <i className="flaticon-help"></i> */}
                          <i className="fa-solid fa-ticket"></i>
                          <h3>Quick Support</h3>
                        </div>
                        <p>24/7 Support</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 d-flex justify-content-center">
                <img
                  src={review2}
                  className="w-75"
                  style={{ borderRadius: "20px" }}
                  alt="Aboutimage"
                ></img>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="   container">
            <div className="row">
              <div className="col-lg-12">
                <div className="choose-content mr-20">
                  <div className=" text-center">
                    <h2>Join the Community</h2>
                    <p className="text-justify">
                      We Invite to become part of something more than just a
                      review platform—it's an opportunity to shape the landscape
                      of informed decision-making. By joining us, you not only
                      gain access to a treasure trove of genuine insights and
                      unbiased reviews from a diverse group of users but also
                      become an integral part of a dynamic community where your
                      voice truly matters. Engage in meaningful conversations,
                      share your experiences, and connect with like-minded
                      individuals who understand the significance of making
                      well-informed choices. Together, we're building a space
                      where opinions flourish, knowledge is shared, and
                      decisions become clearer. So, why just be a spectator when
                      you can be a contributor? Join the Review Master community
                      today and let your opinions shape the future of informed
                      choices!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="contact-form-area pt-70 pb-70">
          <div className="container">
            <div className="section-title text-center">
              <h2 className="mb-5">Send Us a Message Below</h2>
            </div>
            <div className="row pt-45">
              <div className="col-lg-4">
                <div className="contact-info mr-20">
                  <h2>Our Contact Details</h2>
                  <p>Ask me interesting and challenging questions.</p>
                  <ul>
                    <li>
                      <div className="content">
                        <i class="ri-phone-line"></i> <h3>Phone Number</h3>
                        <a href="tel:+91-9063808246">+91-9063808246</a>
                      </div>
                    </li>
                    <li>
                      <div className="content">
                   
                        <i class="ri-map-pin-line"></i>
                        <h3>India Address</h3>
                        <span>
                          CIE IIIT-H,Vindhya C4,<br></br> IIIT-H Campus
                          Gachibowli, Hyderabad, Telangana-500031.
                        </span>
                        <h3>USA Address</h3>
                        <span>
                          Sonix America LLP, 55 Madison Avenue Suite 400,
                          Morristown New Jersey 07960.
                        </span>
                      </div>
                    </li>
                    <li>
                      <div className="content">
                       
                        <i class="ri-folder-info-line"></i>
                        <h3>Contact Info</h3>
                        <a href="mailto:info@sonixhr.com"> info@perfexhr.com</a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="contact-form">
                  <form id="contactForm">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label>
                            Your Name <span>*</span>
                          </label>
                          <input
                            type="text"
                            name="name"
                            id="name"
                            className="form-control"
                            required
                            data-error="Please Enter Your Name"
                            placeholder="Name"
                          ></input>
                          <div className="help-block with-errors"></div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label>
                            Your Email <span>*</span>
                          </label>
                          <input
                            type="email"
                            name="email"
                            id="email"
                            className="form-control"
                            required
                            data-error="Please Enter Your Email"
                            placeholder="Email"
                          ></input>
                          <div className="help-block with-errors"></div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label>
                            Phone Number <span>*</span>
                          </label>
                          <input
                            type="text"
                            name="phone_number"
                            id="phone_number"
                            required
                            data-error="Please Enter Your number"
                            className="form-control"
                            placeholder="Phone Number"
                          ></input>
                          <div className="help-block with-errors"></div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label>
                            Your Subject <span>*</span>
                          </label>
                          <input
                            type="text"
                            name="msg_subject"
                            id="msg_subject"
                            className="form-control"
                            required
                            data-error="Please Enter Your Subject"
                            placeholder="Your Subject"
                          ></input>
                          <div className="help-block with-errors"></div>
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                          <label>
                            Your Message <span>*</span>
                          </label>
                          <textarea
                            name="message"
                            className="form-control"
                            id="message"
                            cols="30"
                            rows="8"
                            required
                            data-error="Write your message"
                            placeholder="Your Message"
                          ></textarea>
                          <div className="help-block with-errors"></div>
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 text-center">
                        <button
                          type="submit"
                          className="default-btn btn-bg-two border-radius-50"
                        >
                          Send Message <i className="bx bx-chevron-right"></i>
                        </button>
                        <div
                          id="msgSubmit"
                          className="h3 text-center hidden"
                        ></div>
                        <div className="clearfix"></div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="contact-form-area pt-70 pb-70">
          <div className="container">
            <div className="section-title text-center">
              <h2 className="mb-5">Send Us a Message Below</h2>
            </div>
            <div className="row pt-45">
              <div className="col-12">
                <div className="contact-info mr-20">
                <div className="contact-form">
                  <form id="contactForm">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label>
                            Your Name *
                          </label>
                          <input
                            type="text"
                              name="Contact Name"
                              value={formData["Contact Name"]}
                              onChange={handleChange}
                            id="name"
                            className="form-control contact-inputs"
                            required
                            data-error="Please Enter Your Name"
                            placeholder="Name"
                          ></input>
                          <div className="help-block with-errors"></div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label>
                            Your Email *
                          </label>
                          <input
                              type="email"
                              onChange={handleChange}
                            name="Contact email"
                              id="email"
                              value={formData["Contact email"]}
                            className="form-control contact-inputs"
                            required
                            data-error="Please Enter Your Email"
                            placeholder="Email"
                          ></input>
                          <div className="help-block with-errors"></div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label>
                            Phone Number *
                          </label>
                          <input
                            type="text"
                              name="Contact number"
                              onChange={handleChange}
                              id="phone_number"
                              value={formData["Contact number"]}
                            required
                            data-error="Please Enter Your number"
                            className="form-control contact-inputs"
                            placeholder="Phone Number"
                          ></input>
                          <div className="help-block with-errors"></div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group ">
                          <label>
                            Your Subject *
                          </label>
                          <input
                            type="text"
                              name="Contact subject"
                              onChange={handleChange}
                            id="msg_subject"
                              className="form-control contact-inputs"
                              value={formData["Contact subject"]}
                            required
                            data-error="Please Enter Your Subject"
                            placeholder="Your Subject"
                          ></input>
                          <div className="help-block with-errors"></div>
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                          <label>
                            Your Message *
                          </label>
                          <textarea
                            name="Contact Message"
                            className="form-control contact-inputs"
                              id="message"
                              onChange={handleChange}
                            cols="30"
                              rows="8"
                              value={formData["Contact Message"]}
                            required
                            data-error="Write your message"
                            placeholder="Your Message"
                          ></textarea>
                          <div className="help-block with-errors"></div>
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 text-center">
                        <button
                            type="submit"
                            onClick={handleSubmit}
                          className="default-btn btn-bg-two border-radius-50"
                        >
                          Send Message <i className="bx bx-chevron-right"></i>
                        </button>
                        <div
                          id="msgSubmit"
                          className="h3 text-center hidden"
                        ></div>
                        <div className="clearfix"></div>
                      </div>
                    </div>
                  </form>
                </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default About;
