import React from "react";
import { Link } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "remixicon/fonts/remixicon.css";
import logo from '../aseets/LOGO2 (1).jpg'

const Footer = () => {
  return (


    <footer class="footer-area footer-bg">
      <div class="container">
        <div class="footer-top pt-50 pb-0 d-flex justify-content-center">
          <div class="row">
            <div class="col-12">
              <div class="footer-widget">
                <div class="footer-logo d-flex justify-content-center">
                  <Link to="">
                    <img
                      src={logo}
                      alt="Images"
                      className="footer-logo"
                    />
                  </Link>
                </div>
                {/* <p>
                  We believe in the power of transparency and information-sharing to empower professionals in making informed career decisions
                </p> */}
                {/* <div class="footer-call-content mt-3">
                                <h3>Talk to Our Support</h3>
                                <span><Link href="tel:+91-9063808246">+91-90638 08246</Link></span>
                                <i class="bx bx-headphone"></i>
                            </div> */}
              </div>
            </div>

            <div class="col-12">
              <div class="footer-widget d-flex justify-content-center">

                <p>
                  By championing a commitment to transparency, we aim to cultivate a community where professionals not only gain access to valuable information but also engage in meaningful dialogues, fostering a collaborative environment that propels collective growth and success in their respective career journeys.              </p>
                {/* <div class="footer-call-content mt-3">
                                <h3>Talk to Our Support</h3>
                                <span><Link href="tel:+91-9063808246">+91-90638 08246</Link></span>
                                <i class="bx bx-headphone"></i>
                            </div> */}
              </div>
              {/* <div class="footer-widget">
                <h3>Contact</h3>
                <ul class="footer-list">

                  <li>
                    <Link to="">
                      <i class="ri-mail-line"></i> info@perfexhr.com
                    </Link>
                  </li>
                  <li>
                    <Link to="">

                      <i class="ri-phone-line"></i> +91-90638 08246
                    </Link>
                  </li>

                  <h5 class="text-white mt-3"> Address</h5>
                  <li>
                    <Link to="">
                      <i class="ri-map-pin-line"></i> 3rd Floor, Akshaya Aracade, Madhapur,  Hyderabad, Telangana-500031.
                    </Link>
                  </li>
                </ul>
                <ul class="footer-list">
                  <h5 class="text-white mt-2">USA Address</h5>
                  <li>
                    <Link to="">
                      <i class="ri-map-pin-line"></i> Sonix America LLP, 55
                      Madison Avenue Suite 400, Morristown New Jersey 07960.
                    </Link>
                  </li>
                </ul>
              </div> */}
            </div>
            <div class="col-12">
              <div class="footer-widget pl-2 d-flex justify-content-center">
                <ul class="footer-list d-flex flex-wrap">
                <li>
                    <Link to="/" >
                      {/* <i class="ri-arrow-right-s-line"></i> */}
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link to="/about" >
                      {/* <i class="ri-arrow-right-s-line"></i> */}
                      About us
                    </Link>
                  </li>
                  
                  <li>
                    <Link to="/businessenquiry" >
                      {/* <i class="ri-arrow-right-s-line"></i> */}
                      Work with Us
                    </Link>
                  </li>
                  
                  <li>
                    <Link to="/about" >
                      {/* <i class="ri-arrow-right-s-line"></i> */}
                      Contact
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="copy-right-area">
          <div class="copy-right-text">
            <p className="p-0 m-0">
              Copyright ©2023 Satiety. All Rights Reserved.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
