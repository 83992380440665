import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { FiEye, FiEyeOff } from "react-icons/fi";
import loginBanner from "../aseets/Instant information-pana.png";
import { Link } from "react-router-dom";
import apiList from "../lib/apiList";
import { ToastContainer, toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const SignupPage = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);

  const [email, setemail] = useState("");
  const [password, setPassword] = useState("");
  const [date, setDate] = useState("");
  const [name, setName] = useState("");
  const [mobile, setmobile] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [occupation, setOccupation] = useState("");

  const onSubmitBtn = (e) => {
    e.preventDefault();
    loginUser();
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      loginUser();
    }
  };

  const loginUser = () => {
    const userData = {
      name: name,
      mobile: mobile,
      email: email,
      password: password,
      confirmPassword: confirmPassword,
    };

    if (
      email &&
      password &&
      date &&
      name &&
      mobile &&
      occupation &&
      confirmPassword !== ""
    ) {
      axios
        .post(apiList.signup, userData)
        .then((response) => {
          if (response.status === 201) {
            toast.success("signup successfull");
         
            setTimeout(() => {
      navigate("/")
            }, 1000);
          }
          else {
            toast.error("error signing up! please try again")
          }
        })
        .catch((error) => {
          console.log(error.response.data);
        });
    } else {
      toast.error("Please enter required details");
    }
  };

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const handleTogglePassword2 = () => {
    setShowPassword1((prevShowPassword) => !prevShowPassword);
  };

  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={1000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        transition={Zoom}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="container" style={{ marginTop: "100px" }}>
        <div className="row login_home">
          <div className="col-md-6">
            <div className="col_6">
              <h4 className="text-center ">Signup</h4>
              <form>
                <label>Full Name</label>
                <input
                  type="text"
                  placeholder="Enter your  Name"
                  className="form-control"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                />
                <label>Email Address</label>
                <input
                  type="text"
                  placeholder="Enter your email"
                  className="form-control"
                  onChange={(e) => setemail(e.target.value)}
                  value={email}
                />
                <label>Date of Birth</label>
                <input
                  type="date"
                  placeholder="Enter your email"
                  className="form-control"
                  onChange={(e) => setDate(e.target.value)}
                  value={date}
                />
                <label>Password</label>
                <div className="password-input">
                  <input
                    type={showPassword ? "text" : "password"}
                    placeholder="Enter your password"
                    className="form-control"
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    onKeyPress={handleKeyPress}
                  />
                  <span onClick={handleTogglePassword} className="eye-icon">
                    {" "}
                    {showPassword ? <FiEyeOff /> : <FiEye />}
                  </span>
                </div>
                <label>Confirm Password</label>
                <div className="password-input">
                  <input
                    type={showPassword1 ? "text" : "password"}
                    placeholder="Enter your password"
                    className="form-control"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    value={confirmPassword}
                    onKeyPress={handleKeyPress}
                  />
                  <span onClick={handleTogglePassword2} className="eye-icon">
                    {" "}
                    {showPassword ? <FiEyeOff /> : <FiEye />}
                  </span>
                </div>
                <label>Mobile Number</label>
                <input
                  type="text"
                  placeholder="Enter your Mobile Number"
                  className="form-control"
                  onChange={(e) => setmobile(e.target.value)}
                  value={mobile}
                />
                <label>Occupation</label>
                <input
                  type="text"
                  placeholder="Enter your occupation"
                  className="form-control"
                  onChange={(e) => setOccupation(e.target.value)}
                  value={occupation}
                />
                
              </form>
              <div className="d-flex align-items-center justify-content-center mt-3 ">
              <button type="submit" className="btn" style={{padding:"8px 25px "}} onClick={onSubmitBtn}>
                  Submit
                </button>
              <p className="text-center ml-3" style={{ marginTop: "10px" }}>
                {" "}
                Existing User?{" "}
                <span style={{ color: "rgb(1, 1, 74)" }}>
                  {" "}
                  <Link
                    to="/login"
                    style={{ color: "rgb(1, 1, 74)", textDecoration: "none" }}
                  >
                    Login
                  </Link>
                </span>
              </p></div>
            </div>
          </div>
          <div
            className="col-md-5 col-3 d-none d-md-block"
            style={{ position: "relative", bottom: "8%" }}
          >
            <div
              className="innerLeft d-none d-md-block"
              style={{ height: "85vh" }}
            >
              <div className="innerLeft-content text-center">
                {/* Your content for the banner */}
                <h5>Welcome to Review Master</h5>
                <p>Some additional information or description...</p>
                <img src={loginBanner} alt="skn" style={{ width: 225 }}></img>
              </div>
            </div>
          </div>

          <div className="col-lg-3"></div>
        </div>
      </div>
    </div>
  );
};

export default SignupPage;
