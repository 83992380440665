import React from 'react'

const Loader = () => {
  return (
    <div>
      <div className="loader-container">
          {/* Add your loader component or styling here */}
          <div class="loader-container-main">
            <div class="ring"></div>
            <div class="ring"></div>
            <div class="ring"></div>
          </div>
        </div>
    </div>
  )
}

export default Loader
