import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import apiList from "../lib/apiList";
import loginBanner from "../aseets/Online Review-pana.png";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { Link } from "react-router-dom";
import { ToastContainer, toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";

const LoginPage = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [email, setemail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const onSubmitBtn = (e) => {
    e.preventDefault();
    if (loading) {
      return;
    }
    loginUser();
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      loginUser();
    }
  };

  
  const loginUser = () => {
    setLoading(true);
    const userData = {
      email: email,
      password: password,
    };

    if (email && password !== "") {
      axios
        .post(apiList.login, userData)
        .then((response) => {
          if (response.status === 200) {
            let jwtToken = response.data.token;
            Cookies.set("jwtToken", jwtToken);

            // Fetch admin emails from the server
            axios
              .get(apiList.adminEmails)
              .then((adminEmailResponse) => {
                const adminEmails = adminEmailResponse.data.emails.map(
                  (e) => e.email
                );

                // Check if the logged-in user is an admin
                const isAdmin = adminEmails.includes(email);
                if (isAdmin) {
                  Cookies.set("loginType", "admin", { expires: 1 / 24 }); 
                  toast.success("Admin login successful");
                  setTimeout(() => {
                    navigate("/Adminhome");
                  }, 1500);
                } else {
                  toast.success("Login successful");
                  setTimeout(() => {
                    navigate("/");
                  }, 1500);
                }
              })
              .catch((error) => {
                console.log("Error fetching admin emails:", error);
              });
          }
        })
        .catch((error) => {
          console.log(error.response.data);
        });
    } else {
      toast.error("Please enter required details");
    }
  };
  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={1000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        transition={Zoom}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="container" style={{ marginTop: "100px" }}>
        <div className="row login_home">
          <div
            className="col-md-5 col-3 d-none d-md-block"
            style={{ position: "relative", bottom: "8%" }}
          >
            <div
              className="innerLeft d-none d-md-block"
              style={{ height: "85vh" }}
            >
              <div className="innerLeft-content">
                {/* Your content for the banner */}
                <h5>Welcome to Review Master</h5>
                <p>Some additional information or description...</p>
                <img src={loginBanner} alt="skn" style={{ width: 195 }}></img>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="col_6">
              <form onSubmit={onSubmitBtn}>
                <h4 className="text-center">Login</h4>
                <label>Email Address</label>
                <input
                  type="text"
                  placeholder="Enter your email"
                  className="form-control"
                  onChange={(e) => setemail(e.target.value)}
                  value={email}
                />
                <label>Password</label>
                <div className="password-input">
                  <input
                    type={showPassword ? "text" : "password"}
                    placeholder="Enter your password"
                    className="form-control"
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    onKeyPress={handleKeyPress}
                  />
                  <span onClick={handleTogglePassword} className="eye-icon">
                    {" "}
                    {showPassword ? <FiEyeOff /> : <FiEye />}
                  </span>
                </div>
                <p className="text-end mt-1 mb-0">Forgot Password ?</p>
                <button type="submit" className="btn w-100 mt-3">
                  Login
                </button>
              </form>
              <p className="text-center" style={{ marginTop: "5px" }}>
                {" "}
                Don't have an account?{" "}
                <span style={{ color: "rgb(1, 1, 74)" }}>
                  {" "}
                  <Link
                    to="/signup"
                    style={{ color: "rgb(1, 1, 74)", textDecoration: "none" }}
                  >
                    Signup
                  </Link>
                </span>
              </p>
            </div>
          </div>
          <div className="col-lg-3"></div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
