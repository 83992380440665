import React, { useState } from "react";
import { Link } from "react-router-dom";
import Adminnav from "./AdminNav";
import Footer from "../components/Footer";

import "bootstrap/dist/css/bootstrap.min.css";
import apiList from "../lib/apiList";
import axios from "axios";
import { FiEye, FiEyeOff } from "react-icons/fi";
import loginBanner from "../aseets/Instant information-pana.png";
import { ToastContainer, toast, Zoom } from "react-toastify";

const AddCompany = () => {
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [website, setWebsite] = useState("");
  const [description, setDescription] = useState("");
  const [foundedIn, setFoundedIn] = useState("");
  const [ownership, setOwnership] = useState("");
  const [employeeCount, setEmployeeCount] = useState("");
  const [headquaters, setHeadQuaters] = useState("");
  const [place, setPlace] = useState("");
  const [CEO, setCEO] = useState("");
  const [founders, setFounders] = useState("");
  const [socialMedia, setSocialMedia] = useState("");
  const [category, setCategory] = useState("");
  const [logoURL, setUrl] = useState("");

  const categoryArray = [
    "IT Services and Consulting",
    "Food Processing",
    "Automobile",
    "Electronics",
    "Banking/Finance",
    "Travel",
    "Books/Printing and Publishing",
    "Media and Entertainment",
    "Educational institutions",
    "Small-Business - Malls - Stores",
    "Home and Appliances",
    "Real Estate / Construction",
    "Others",
  ];

  const ownershipArray = ["Private", "Government"];
  // Handles form submission to add a new company to the database

  const [formData, setFormData] = useState({
    companyName: "",
    companyEmail: "",
    contactNo: "",
    website: "",
    description: "",
    foundedIn: "",
    ownership: "",
    numberOfEmployees: "",
    headquaters: "",
    place: "",
    CEO: "",
    founders: "",
    socialMediaPresence: "",
    category: "",
    logoUrl: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(apiList.addCompany, formData);

      toast.success("company added successfully");
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } catch (error) {
      console.log(error.response.data);
      console.log("Error adding company:", error);
    }
  };


  return (
    <div class="">
      <Adminnav></Adminnav>
      
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        transition={Zoom}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    
      <div className="container mb-5" style={{ marginTop: "200px" }}>
        <div
          className="row  addCompany"
          style={{
            margin: "auto",
          }}
        >
          <div
            className="col-12"
            style={{ position: "relative", bottom: "4%" }}
          >
            <div className="" style={{ height: "8vh" }}>
              <div className="innerLeft-contentAd  text-center">
                <h4
                  style={{
                    width: "max-content",
                    backgroundColor: "rgb(1, 1, 74)",
                    padding: "20px 40px",
                    borderRadius: "10px",
                  }}
                >
                  Add Company
                </h4>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row addCompany_home">
              <div className="col-md-12">
                <div className="col_6">
                  <form onSubmit={handleSubmit} className="row">
                    <div className="col-md-6 col-lg-4">
                      <label>Company Name</label>
                      <input
                        type="text"
                        placeholder=""
                        className="form-control"
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            companyName: e.target.value,
                          })
                        }
                        value={formData.companyName}
                      />
                    </div>

                    <div className="col-md-6 col-lg-4">
                      <label>Email</label>
                      <input
                        type="text"
                        placeholder=""
                        className="form-control"
                        onChange={(e) =>
                          setFormData({ ...formData, companyEmail: e.target.value })
                        }
                        value={formData.companyEmail}
                      />
                    </div>

                    <div className="col-md-6 col-lg-4">
                      <label>Phone Number</label>
                      <input
                        type="text"
                        placeholder=""
                        className="form-control"
                        onChange={(e) =>
                          setFormData({ ...formData, contactNo: e.target.value })
                        }
                        value={formData.contactNo}
                      />
                    </div>

                    <div className="col-md-6 col-lg-4">
                      <label>Website</label>
                      <input
                        type="text"
                        placeholder=""
                        className="form-control"
                        onChange={(e) =>
                          setFormData({ ...formData, website: e.target.value })
                        }
                        value={formData.website}
                      />
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <label>Founded In</label>
                      <input
                        type="text"
                        placeholder=""
                        className="form-control"
                        onChange={(e) =>
                          setFormData({ ...formData, foundedIn: e.target.value })
                        }
                        value={formData.foundedIn}
                      />
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <label for="category">Category</label>
                      <select
                        id="category"
                        class="form-control browser-default custom-select"
                        value={formData.category}
                        onChange={(e) =>
                          setFormData({ ...formData, category: e.target.value })
                        }
                      >
                        <option hidden selected>
                          Choose Category
                        </option>
                        {categoryArray.map((categoryOption) => (
                          <option key={categoryOption} value={categoryOption}>
                            {categoryOption}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div class=" col-lg-4 col-md-6 ">
                      <label for="address-1">Place</label>
                      <input
                        type="address"
                        class="form-control"
                        name="Locality"
                        id="address-1"
                        placeholder=""
                        required
                        value={formData.place}
                        onChange={(e) =>
                          setFormData({ ...formData, place: e.target.value })
                        }
                      />
                    </div>

                    <div class=" col-lg-4 col-md-6">
                      <label for="ownership">Ownership</label>
                      <select
                        id="ownership"
                        class="form-control browser-default custom-select"
                        value={formData.ownership}
                        onChange={(e) =>
                          setFormData({ ...formData, ownership: e.target.value })
                        }
                      >
                        <option hidden selected>
                          Choose Ownership
                        </option>
                        {ownershipArray.map((ownershipOption) => (
                          <option key={ownershipOption} value={ownershipOption}>
                            {ownershipOption}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div class="  col-lg-4 col-md-6">
                      <label for="pass2">Employee Count</label>
                      <input
                        type="text"
                        name="cnf-password"
                        class="form-control"
                        id="pass2"
                        placeholder=""
                        required
                        value={formData.numberOfEmployees}
                        onChange={(e) =>
                          setFormData({ ...formData, numberOfEmployees: e.target.value })
                        }
                      />
                    </div>
                    <div class=" col-lg-4 col-md-6">
                      <label for="zip">Headquarters</label>
                      <input
                        type="zip"
                        class="form-control"
                        name="Zip"
                        id="zip"
                        placeholder=""
                        required
                        value={formData.headquaters}
                        onChange={(e) =>
                          setFormData({ ...formData, headquaters: e.target.value })
                        }
                      />
                    </div>

                    <div class=" col-lg-4 col-md-6">
                      <label for="Date">Logo URL</label>
                      <input
                        type="text"
                        name="dob"
                        class="form-control"
                        id="Date"
                        placeholder=""
                        required
                        value={formData.logoUrl}
                        onChange={(e) =>
                          setFormData({ ...formData, logoUrl: e.target.value })
                        }
                      />
                    </div>
                    <div class=" col-lg-4 col-md-6">
                      <label for="sex">CEO</label>
                      <input
                        type="text"
                        name="dob"
                        class="form-control"
                        id="Date"
                        placeholder=""
                        required
                        value={formData.CEO}
                        onChange={(e) =>
                          setFormData({ ...formData, CEO: e.target.value })
                        }
                      />
                    </div>
                    <div class=" col-lg-4 col-md-6">
                      <label for="cod">Founders</label>

                      <input
                        type="text"
                        name="dob"
                        class="form-control"
                        id="Date"
                        placeholder=""
                        required
                        value={formData.founders}
                        onChange={(e) =>
                          setFormData({ ...formData, founders: e.target.value })
                        }
                      />
                    </div>

                    <div class=" col-lg-4 col-md-6">
                      <label for="pass">Social Media Presence</label>
                      <input
                        type="text"
                        name="password"
                        class="form-control"
                        id="pass"
                        placeholder=""
                        required
                        value={formData.socialMediaPresence}
                        onChange={(e) =>
                          setFormData({ ...formData, socialMediaPresence: e.target.value })
                        }
                      />
                    </div>
                    {/* <div class=" col-lg-4 col-md-6">
                      <label for="pass2">Website</label>
                      <input
                        type="text"
                        name="cnf-password"
                        class="form-control"
                        id="pass2"
                        placeholder=""
                        required
                        value={website}
                        onChange={(e) => setWebsite(e.target.value)}
                      />
                    </div> */}

                    <div className="col-md-6 col-lg-4">
                      <label>Description</label>
                      <textarea
                        placeholder="Enter company description"
                        className="form-control"
                        rows={3}
                        onChange={(e) =>
                          setFormData({ ...formData, description: e.target.value })
                        }
                        value={formData.description}
                      />
                    </div>

                    {/* Add similar blocks for other form fields */}
                    {/* ... */}
                  </form>
                  <div className="d-flex justify-content-center">
                    <button
                      type="submit"
                      onClick={handleSubmit}
                      className="text-center btn px-4 mt-3"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>

              <div className="col-lg-3"></div>
            </div>
          </div>
        </div>
      </div>

      <Footer></Footer>
    </div>
  );
};

export default AddCompany;
