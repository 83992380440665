import React, { useState } from "react";
import UserNavbar from "./usernavbar";
import Footer from "../components/Footer";
import indiaLogo from "../aseets/india.png";
import axios from "axios";
import { toast, Zoom, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Adminnav from "../AdminInterface/AdminNav";
import Cookies from "js-cookie";

const BussinessEnquiry = () => {
  const loginType = Cookies.get("loginType");
  const [formData, setFormData] = useState({
    Name: "",
    Designation: "",
    "Company Name": "",
    Email: "",
    "Phone Number": "",
    Website: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const dataUrl = " ";

      const response = await axios.post(dataUrl, formData, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      setFormData({
        Name: "",
        Designation: "",
        "Company Name": "",
        Email: "",
        "Phone Number": "",
        Website: "",
      });

      toast.success("Thank you for reaching us ! We will respond shortly");
      // Optionally, you can handle the response or perform other actions.
    } catch (error) {
      console.error("Error:", error);
      // Handle the error as needed.
    }
  };
  return (
    <div>
      {loginType === "admin" ? <Adminnav /> : <UserNavbar />}
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        transition={Zoom}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="" style={{ marginTop: "70px" }}>
        <div className="enquiry-left ">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 d-flex align-items-center justify-content-center">
                <div className="enquiry-left-content  ">
                  <h3 className="section-title">
                    Elevate your business presence{" "}
                  </h3>

                  <div className="section-subtitle">
                    Enhance Online Reputation Management (ORM) through customer
                    reviews.
                    <p>
                      Unlock the potential of your brand by featuring it on our
                      review website
                    </p>
                  </div>

                  <ul className="bulletPoints text-start p-0">
                    <li>
                      <i className="fa-solid fa-circle-check"></i>Benefit from
                      community-powered insights
                    </li>
                    <li>
                      <i className="fa-solid fa-circle-check"></i>Access
                      unbiased reviews for genuine feedback
                    </li>
                    <li>
                      <i className="fa-solid fa-circle-check"></i>Transparency
                      is Key
                    </li>
                    <li>
                      <i className="fa-solid fa-circle-check"></i>Smart
                      Recommendations Workforce Happiness
                    </li>
                    <li>
                      <i className="fa-solid fa-circle-check"></i>Engage and
                      connect with a broader audience through our platform
                    </li>
                    <li>
                      <i className="fa-solid fa-circle-check"></i>Your Voice
                      Matters
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-md-6 d-flex align-items-center justify-content-center">
                <div className="container enquiry-right-content w-100 ">
                  <form onSubmit={handleSubmit}>
                    <input
                      className="form-control"
                      type="text"
                      required
                      placeholder="Name"
                      name="Name"
                      value={formData.Name}
                      onChange={handleChange}
                    />
                    <input
                      className="form-control"
                      type="text"
                      required
                      placeholder="Designation"
                      name="Designation"
                      value={formData.Designation}
                      onChange={handleChange}
                    />
                    <input
                      className="form-control"
                      type="text"
                      required
                      placeholder="Company / Brand Name"
                      name="Company Name"
                      value={formData["Company Name"]}
                      onChange={handleChange}
                    />
                    <input
                      className="form-control"
                      type="text"
                      required
                      placeholder="Email Address"
                      name="Email"
                      value={formData.Email}
                      onChange={handleChange}
                    />

                    <div className="phone-input" style={{ marginTop: "1rem" }}>
                      <div
                        className="country-code"
                        style={{ marginLeft: "10px" }}
                      >
                        <img src={indiaLogo} alt="India Flag" width={30} />
                        <span
                          className=""
                          style={{ color: "#495057", marginLeft: "3px" }}
                        >
                          +91
                        </span>
                      </div>
                      <input
                        className="w-100"
                        type="text"
                        style={{
                          margin: "0",
                          border: "none",
                          outline: "none",
                          padding: "10px",
                        }}
                        required
                        placeholder="Phone number"
                        name="Phone Number"
                        value={formData["Phone Number"]}
                        onChange={handleChange}
                      />
                    </div>

                    <input
                      className="form-control"
                      type="text"
                      required
                      placeholder="Website"
                      name="Website"
                      value={formData.Website}
                      onChange={handleChange}
                    />
                    <p className="mt-2">
                      By providing this information, you agree to the Terms of
                      Services and Privacy Policy
                    </p>
                    <button type="submit" className="enquiryBtn">
                      Submit
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default BussinessEnquiry;
