import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import UserNavbar from "./usernavbar";
import Footer from "../components/Footer";
import apiList from "../lib/apiList";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Infinity from "../AdminInterface/infinityscroll";
import { Tabs, Tab } from "react-bootstrap";
import homeImg from "../aseets/Hotel Booking-cuate.png";
import Loader from "../components/loader";

const testimonialLogo = require("../aseets/team.png");

const Testimonials = [
  {
    description: `As a proud partner of Review Master, our company values the commitment to transparency and employee feedback that this platform facilitates. We believe in the power of honest reviews to foster a positive work environment. Review Master's unbiased approach allows our employees to share their experiences openly, contributing to a culture of continuous improvement. This partnership has been instrumental in strengthening our employer brand and showcasing our dedication to employee satisfaction`,
    givenBy: "JackS18",
    comments: 0,
  },
  {
    description:
      "Review Master has become an integral part of our commitment to employee engagement and satisfaction. Being associated with this platform has allowed us to demonstrate our dedication to transparency and accountability. The unbiased reviews on Review Master provide valuable insights into our workplace culture, helping us identify areas for improvement and reinforcing our commitment to fostering a positive work environment. We are proud to be affiliated with a platform that values honesty and openness in employee feedback",
    givenBy: "Famous People",
    comments: 0,
  },
  {
    description:
      "As a company partnered with Review Master, we appreciate the platform's dedication to providing an unbiased space for employees to share their thoughts. This partnership has not only strengthened our relationship with our workforce but has also enhanced our reputation as an employer who values transparency and open communication. Review Master's commitment to impartiality aligns seamlessly with our values, and we are grateful for the opportunity to showcase our commitment to employee satisfaction through this valuable platform.",
    givenBy: "FullAstro",
    comments: 0,
  },
];

const Testimonial = ({ description, givenBy, comments }) => (
  <div className="article ">
    <div className="m-3 text-center ">
      <img
        src={testimonialLogo}
        width={80}
        alt="testimonialLogo"
        style={{ borderRadius: "100%" }}
      ></img>{" "}
    </div>
    <div>
      <span className="mb-3">{description}</span>{" "}
      <p className="mt-1">
        by : <span style={{ fontWeight: "500" }}> {givenBy}</span>
      </p>
      <span>{comments} comments</span>
    </div>
  </div>
);

const Userhome = () => {
  const [companies, setComapnies] = useState([]);
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [companyNameInput, setCompanyNameInput] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [key, setKey] = useState("healthcare-insight");
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const [tab1Open, setTab1Open] = useState(false);
  const [tab2Open, setTab2Open] = useState(false);
  const [tab3Open, setTab3Open] = useState(false);
  const [tab4Open, setTab4Open] = useState(false);
  const closeAllTabs = () => {
    setTab1Open(false);
    setTab2Open(false);
    setTab3Open(false);
    setTab4Open(false);
  };
  const toggleTab = (tabNumber) => {
    closeAllTabs();
    switch (tabNumber) {
      case 1:
        setTab1Open(!tab1Open);
        break;
      case 2:
        setTab2Open(!tab2Open);
        break;
      case 3:
        setTab3Open(!tab3Open);
        break;
      case 4:
        setTab4Open(!tab4Open);
        break;
      default:
        break;
    }
  };

  const allCompanies = async (req, res) => {
    try {
      setLoading(true);
      const response = await axios.get(apiList.allCompanies);
      setComapnies(response.data);
      setFilteredCompanies(response.data);
      setLoading(false);
    } catch (err) {
      console.error("Error searching for companies:", err);
    }
  };

  const handleCategoryClick = (category) => {
    // Set the selected category and filter the companies based on it
    setSelectedCategory(category);
    // Assuming you have a list of all companies stored in some variable (allCompanies)
    const companiesFilteredByCategory = companies.filter(
      (company) => company.category.toLowerCase() === category.toLowerCase()
    );
    setFilteredCompanies(companiesFilteredByCategory);
  };

  const handleSearch = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    const filtered = companies.filter((company) =>
      company.companyName.toLowerCase().includes(term.toLowerCase())
    );
    setFilteredCompanies(filtered);
  };

  useEffect(() => {
    allCompanies();
    setSearchTerm("");
  }, []);
  const GotoReviewComponent = (companyName) => {
    navigate("/Userreview", { state: { companyName: companyName } });
  };

  const GotoAllReviews = (companyName) => {
    navigate("/allcompanies");
  };

 

 
  return (

    <div>
        <UserNavbar />
      {loading ? (
        
        <Loader/>
      ) : (
        <div>
    

      {/* banner */}

    
      
          <div className={"banner"}>
            <div className="container">
              <div className={"banner__title"}>
                Your Ultimate Destination for Unbiased Reviews
              </div>
              <div className={"banner__subtitle"}>
                Unlock Wisdom, Choose Wisely.
              </div>
              <input
                type="search"
                placeholder="Search for brands ..."
                className={"banner__search"}
                value={searchTerm}
                onChange={handleSearch}
              />
              <div className={"banner__categories "}>
                <span
                  onClick={() => handleCategoryClick("Automobile")}
                  className="selectedDiv"
                >
                  Automobile
                </span>
                <span
                  onClick={() => handleCategoryClick("Electronics")}
                  className="selectedDiv"
                >
                  Electronics
                </span>
                <span
                  onClick={() => handleCategoryClick("IT Services and Consulting")}
                  className="selectedDiv"
                >
                  IT Services and Consulting
                </span>
                <span
                  onClick={() => handleCategoryClick("Educational institutions")}
                  className="selectedDiv"
                >
                  Educational institutions
                </span>
                <span
                  onClick={() => handleCategoryClick("Home and Appliances")}
                  className="selectedDiv"
                >
                  Home and Appliances
                </span>
                <span
                  onClick={() => handleCategoryClick("Travel")}
                  className="selectedDiv"
                >
                  Travel
                </span>
                <span
                  onClick={() => handleCategoryClick("Banking/Finance")}
                  className="selectedDiv"
                >
                  Banking/Finance
                </span>
              </div>
              {/* <div className={"banner__links"}>
          <div className={"banner__link"}>Verified Reviews</div>
          <div className={"banner__link"}>Top Articles</div>
          <div className={"banner__link"}>Write Share Win Contest</div>
          <div className={"banner__link"}>Mouth Shut for Brands</div>
        </div> */}
            </div>
          </div>
          <div
            className="container mt-4 "
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <span>Recent Reviews</span>
            <span className="d-none d-md-block" onClick={() => GotoAllReviews()}>
              See more reviews <i class="ri-arrow-right-line"></i>
            </span>
          </div>
          <div className={filteredCompanies.length > 0 ? "reviews container" : ""}>
            {filteredCompanies.length === 0 ? (
              <div className="container mt-3  text-center">
                <h6>
                  Oops! We couldn't find your company , please double-check the
                  spelling or provide details you would wish to{" "}
                </h6>

                <h6>
                  We will get back the company as soon as possible. Thank you !!
                </h6>
                {/* <form onSubmit={handleSubmit}>
        <label>
          Company Name:
          <input type="text" value={companyNameInput} onChange={handleInputChange} />
        </label>
        <button type="submit">Submit</button>
            </form> */}
                <div className="contact-form mt-3">
                  <form id="contactForm">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="form-group">
                          <label className="d-flex align-self-start">
                            Company Name <span>*</span>
                          </label>
                          <input
                            type="text"
                            name="name"
                            id="name"
                            className="form-control"
                            required
                            data-error="Please Enter Your Name"
                            placeholder="Name"
                          ></input>
                        </div>
                      </div>

                      <div className="col-lg-12 col-md-12 text-center">
                        <button
                          type="submit"
                          className="default-btn btn-bg-two border-radius-50"
                        >
                          Send Message <i className="bx bx-chevron-right"></i>
                        </button>
                        <div id="msgSubmit" className="h3 text-center hidden"></div>
                        <div className="clearfix"></div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            ) : (
              filteredCompanies.slice(0, 8).map((review, index) => (
                <div
                  className="review"
                  key={index}
                  onClick={() => GotoReviewComponent(review.companyName)}
                >
                  <div className="logo text-center">
                    <img
                      src={review.logoUrl}
                      // width={100}
                      style={{ borderRadius:"5px" }}
                      width={54}
                      height={54}
                      alt="logo"
                    ></img>
                  </div>
                  <div className="name mt-1">{review.companyName}</div>
                  <div className="rating">Rating : {review.averageRating} / 5</div>
                  <div>
                    {" "}
                    <i className="ri-star-fill star ratingStars"></i>
                    <i className="ri-star-fill star ratingStars ml-1"></i>
                    <i className="ri-star-fill star ratingStars ml-1"></i>
                    <i className="ri-star-fill star ratingStars ml-1"></i>
                    <i className="ri-star-fill star ratingStars ml-1"></i>
                  </div>

                  <div
                    className="my-2 text-center"
                    style={{
                      border: "1px solid black",
                      padding: "7px 10px",
                      borderRadius: "10px",
                    }}
                  >
                    Read Complete Review
                  </div>
                </div>
              ))
            )}
          </div>
          <div
            className="container mb-4 "
            style={{ display: "flex", justifyContent: "end" }}
          >
            <span className="d-md-none" onClick={() => GotoAllReviews()}>
              See more reviews <i class="ri-arrow-right-line"></i>
            </span>
          </div>
          <div className="container-fluid tabs-main-container">
            <div className="container">
              <div className="row">
                <div className="col-lg-6" style={{ margin: "auto" }}>
                  <div className="tabs-left-container ">
                    <div className="container my-4" style={{ padding: "inherit", textAlign: "justify" }}>
                      <span className="home-left-text">
                        Step into a realm of genuine workplace insights at Review Master, where professionals like you play a pivotal role
                        in shaping the narrative of work experiences. Our platform is a
                        canvas for your reviews, offering a space to candidly express
                        your thoughts on company culture, leadership, and overall job
                        satisfaction. Your reviews serve as a compass for others
                        navigating their career paths and contribute to a collective
                        pool of knowledge that empowers professionals in making informed
                        decisions. Whether you're sharing success stories or providing
                        constructive feedback, every review adds a brushstroke to the
                        vibrant tapestry of workplace realities.
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="tabs-container " style={{ color: "#fff" }}>
                    <div >
                      <h6 className="mb-4">Why Review Master ?</h6>
                      <div className="tab">
                        <div className="tab-header" onClick={() => toggleTab(1)}>
                          {tab1Open ? "-" : "+"} Empower Your Voice, Shape Your
                          Workplace
                        </div>
                        {tab1Open && (
                          <div className="tab-content">
                            A dedicated space to share authentic reviews about their
                            workplace experiences, fostering a community where voices
                            matter. Engage in constructive conversations with fellow
                            professionals, exchanging insights that can contribute to
                            positive changes within organizations.
                          </div>
                        )}
                      </div>

                      <div className="tab">
                        <div className="tab-header" onClick={() => toggleTab(2)}>
                          {tab2Open ? "-" : "+"} Navigating Career Journeys, Inspiring
                          Growth
                        </div>
                        {tab2Open && (
                          <div className="tab-content">
                            Explore personal narratives of career growth and
                            professional development shared by employees across
                            various industries.Provide insights that help employees
                            make informed decisions about their career paths and
                            encourage companies to invest in the growth of their
                            workforce.
                          </div>
                        )}
                      </div>

                      <div className="tab">
                        <div className="tab-header" onClick={() => toggleTab(3)}>
                          {tab3Open ? "-" : "+"} Building Bonds Beyond the Workspace
                        </div>
                        {tab3Open && (
                          <div className="tab-content">
                            Explore stories of how companies foster a sense of
                            community among employees through team-building
                            activities. Share experiences that highlight the
                            importance of a connected and supportive work community in
                            promoting job satisfaction
                          </div>
                        )}
                      </div>

                      <div className="tab">
                        <div className="tab-header" onClick={() => toggleTab(4)}>
                          {tab4Open ? "-" : "+"} Thriving in Harmony; Balancing Work
                          and Life
                        </div>
                        {tab4Open && (
                          <div className="tab-content">
                            Navigate the intricate balance between work and personal
                            life through shared experiences from employees across
                            diverse professions. Engage in discussions on effective
                            strategies for maintaining a healthy work-life balance,
                            promoting overall well-being.
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container my-5">
            <div className="articles">
              <h3 className="mb-4">What our brands are saying about us</h3>
              {Testimonials.map((article, index) => (
                <Testimonial key={index} {...article} />
              ))}
            </div>
            <div className="d-flex justify-content-center">
            </div>
          </div>
       
            <Footer />
            </div>
      )}
    </div>
  );
};

export default Userhome;
