import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from "../aseets/LOGO2 (1).jpg";
import alert from "../aseets/alert.png";

const UserNavbar = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const toggleMenu = () => {
    document.body.classList.toggle("open");
    setMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    document.body.classList.remove("open");
    setMenuOpen(false);
  };

  const token = Cookies.get("jwtToken");
  const loginType = Cookies.get("loginType");

  const logoutFunction = () => {
    Cookies.remove("jwtToken");
    Cookies.remove("loginType");
    navigate("/");
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 20000); // Simulating a 20-second loading time
  }, []);

  useEffect(() => {
    if (!loading && !token) {
      const interval = setInterval(() => {
        setShowLoginModal(true);
      }, 7000); // Show modal every 10 seconds if token is not found

      return () => clearInterval(interval);
    }
  }, [loading, token]);

  const handleLoginClose = () => {
    setShowLoginModal(false);
  };

  return (
    <div>
      <div
        className="modal"
        style={{ display: showLoginModal ? "block" : "none" }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="d-flex justify-content-around ">
              <h5 className="modal-title py-5">Please login to continue</h5>
              <button
                type="button"
                className="close"
                onClick={handleLoginClose}
              >
                <span>&times;</span>
              </button>
            </div>
            <div className="modal-body d-flex justify-content-center">
              <img src={alert} alt="alert" className="w-25" />
            </div>
            <div className="d-flex justify-content-end">
              <Link
                className="mx-4"
                style={{ color: "white", textDecoration: "none" }}
                to="/login"
                onClick={closeMenu}
              >
                <button
                  type="button"
                  className="alertbutton my-3 mx-2"
                  onClick={handleLoginClose}
                >
                  Login
                </button>
              </Link>
            </div>
            {/* <div className="modal-footer">
             
            </div> */}
          </div>
        </div>
      </div>

      <nav className="navbar d-flex justify-content-md-around fixed-top">
        <div className="ml-3">
          <img className="navbar-logo" src={logo} alt="Logo" />
        </div>
        <div>
          <nav className="d-none mainDiv d-md-block">
            <Link
              className="mx-4"
              style={{ color: "white", textDecoration: "none" }}
              to="/"
              onClick={closeMenu}
            >
              HOME
            </Link>
            <Link
              className="mx-4"
              style={{ color: "white", textDecoration: "none" }}
              to="/about"
              onClick={closeMenu}
            >
              ABOUT
            </Link>
            <Link
              className="mx-4"
              style={{ color: "white", textDecoration: "none" }}
              to="/businessenquiry"
              onClick={closeMenu}
            >
              FOR BUSINESS
            </Link>

            {loginType === "admin" && (
              <Link
                className="mx-4"
                style={{ color: "white", textDecoration: "none" }}
                to="/Adminhome"
                onClick={closeMenu}
              >
                ADMIN
              </Link>
            )}

            {token ? (
              <button
                className="reviewButton mx-4"
                style={{ color: "white", textDecoration: "none" }}
                onClick={logoutFunction}
              >
                {" "}
                <i class="ri-logout-box-r-line"></i> LOGOUT
              </button>
            ) : (
              <Link
                className="mx-4"
                style={{ color: "white", textDecoration: "none" }}
                to="/login"
                onClick={closeMenu}
              >
                <button className="reviewButton ">LOGIN / SIGNUP</button>
              </Link>
            )}
          </nav>
        </div>

        <button
          className="navbar-burger d-md-none "
          onClick={toggleMenu}
        ></button>
        <nav className={`menu d-md-none ${isMenuOpen ? "open" : ""}`}>
          <Link to="/" style={{ animationDelay: "0.1s" }} onClick={closeMenu}>
            HOME
          </Link>
          <Link
            to="/about"
            style={{ animationDelay: "0.2s" }}
            onClick={closeMenu}
          >
            ABOUT
          </Link>
          <Link
            to="/businessenquiry"
            style={{ animationDelay: "0.5s" }}
            onClick={closeMenu}
          >
            FOR BUSINESS
          </Link>
          {loginType === "admin" && (
            <Link
              className="mx-4"
              style={{ color: "white", textDecoration: "none" }}
              to="/Adminhome"
              onClick={closeMenu}
            >
              ADMIN
            </Link>
          )}
          {token ? (
            <button
              className="reviewButton mx-4"
              style={{ color: "white", textDecoration: "none" }}
              onClick={logoutFunction}
            >
              {" "}
              <i class="ri-logout-box-r-line"></i> LOGOUT
            </button>
          ) : (
            <Link
              className="mx-4"
              style={{ color: "white", textDecoration: "none" }}
              to="/login"
              onClick={closeMenu}
            >
              <button className="reviewButton ">LOGIN / SIGNUP</button>
            </Link>
          )}
        </nav>
      </nav>
    </div>
  );
};

export default UserNavbar;
