import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Modal, Button, Form } from "react-bootstrap";
import axios from "axios";
import apiList from "../lib/apiList";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from '../aseets/LOGO2 (1).jpg'

const Adminnav = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [showAddEmailModal, setShowAddEmailModal] = useState(false);
  const [newEmail, setNewEmail] = useState("");

  

  const closeMenu = () => {
    document.body.classList.remove("open");
    setMenuOpen(false);
  };

  const navigate = useNavigate();

  const toggleMenu = () => {
    document.body.classList.toggle("open");
    setMenuOpen(!isMenuOpen);
  };

  const formData = {
    email: newEmail,
  };
  const handleAddEmailSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(apiList.addAdminEmails, formData);
    } catch (error) {
      console.log(error.response.data);
      console.log("Error adding company:", error);
    }

    // Add logic to submit the new email

    // Close the modal
    setShowAddEmailModal(false);
    // Reset the input field
    setNewEmail("");
  };

  const handleLogout = () => {
    // Remove JWT token and loginType from cookies
    Cookies.remove("jwtToken");
    Cookies.remove("loginType");

    // Redirect to the login page or any other desired page
    navigate("/");
  };

  useEffect(() => {
    // Check if loginType is set to "admin" in cookies
    const loginType = Cookies.get("loginType");
    const token = Cookies.get("jwtToken");
    if (!loginType || !token || loginType !== "admin") {
      // Navigate to User home if loginType is not set or not "admin"
      navigate("/");
    }
  }, []);
  return (
    <div>
      <nav className="navbar d-flex justify-content-md-around fixed-top">
        <div className="ml-3">
          <img className="navbar-logo" src={logo} alt="Logo" />
        </div>
        <div>
          <nav className="d-none mainDiv d-md-block">
            <Link
              className="mx-3"
              style={{ color: "white", textDecoration: "none" }}
              to="/Adminhome"
            >
              HOME
            </Link>
            <Link
              className="mx-3"
              style={{ color: "white", textDecoration: "none" }}
              to="/about"
            >
              ABOUT
            </Link>
            <Link
              className="mx-3"
              style={{ color: "white", textDecoration: "none" }}
              to="/"
            >
              USER
            </Link>
            <Link
              className="mx-3"
              style={{ color: "white", textDecoration: "none" }}
              onClick={() => setShowAddEmailModal(true)}
            >
              ADD EMAIL
            </Link>
            <Modal
              show={showAddEmailModal}
              onHide={() => setShowAddEmailModal(false)}
            >
              <Modal.Header closeButton>
                <Modal.Title>Add Email</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form onSubmit={handleAddEmailSubmit}>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>
                      Enter the email address required to access the adminside.
                    </Form.Label>
                    <Form.Control
                      type="email"
                      placeholder=""
                      value={newEmail}
                      onChange={(e) => setNewEmail(e.target.value)}
                      required
                    />
                  </Form.Group>
                  {/* <Button className="reviewButton" type="submit">Submit</Button> */}
                  <button className="modalBtn mt-2">Submit</button>
                </Form>
              </Modal.Body>
            </Modal>
            <Link
              className="mx-3"
              style={{ color: "white", textDecoration: "none" }}
              to="/Addcompany"
            >
              ADD COMPANY
            </Link>
            <Link
              className="mx-3"
              style={{ color: "white", textDecoration: "none" }}
              to="/"
              onClick={handleLogout}
            >
              <button className="reviewButton ">
                <i class="ri-logout-box-r-line"></i> LOGOUT
              </button>
            </Link>
          </nav>
        </div>

        <button
          className="navbar-burger d-md-none "
          onClick={toggleMenu}
        ></button>
        <nav className={`menu d-md-none ${isMenuOpen ? "open" : ""}`}>
          <Link to="/Adminhome" style={{ animationDelay: "0.1s" }} onClick={closeMenu}>
            HOME
          </Link>
          <Link to="/about" style={{ animationDelay: "0.2s" }} onClick={closeMenu}>
            ABOUT
          </Link>
          <Link to="/" style={{ animationDelay: "0.3s" }} onClick={closeMenu}>
            USER
          </Link>

          <Link
            style={{
              animationDelay: "0.4s",
              color: "#F9F9F9",
              fontSize: "13px",
              letterSpacing: "0.1rem",
            }}
            onClick={() => setShowAddEmailModal(true)}
          >
            {" "}
            ADD EMAIL
          </Link>
          <Modal
            show={showAddEmailModal}
            onHide={() => setShowAddEmailModal(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>Add Email</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={handleAddEmailSubmit}>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>
                    Enter the email address required to access the adminside.
                  </Form.Label>
                  <Form.Control
                    type="email"
                    placeholder=""
                    value={newEmail}
                    onChange={(e) => setNewEmail(e.target.value)}
                    required
                  />
                </Form.Group>
                {/* <Button className="reviewButton" type="submit">Submit</Button> */}
                <button className="modalBtn mt-2">Submit</button>
              </Form>
            </Modal.Body>
          </Modal>
          <Link
            style={{
              color: "white",
              textDecoration: "none",
              animationDelay: "0.4s",
            }}
            to="/Addcompany"
            onClick={closeMenu}
          >
            ADD COMPANY
          </Link>
          <Link
            className="mx-3"
            style={{ color: "white", textDecoration: "none" }}
            to="/"
            onClick={closeMenu}
          >
            <button className="reviewButton ">
              {" "}
              <i class="ri-logout-box-r-line"></i> LOGOUT
            </button>
          </Link>
        </nav>
      </nav>
    </div>
  );
};

export default Adminnav;
