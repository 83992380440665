// export const server = "http://localhost:4444";
export const server = `https://review-master-server.onrender.com`;
// export const server = `https://real-ruby-cocoon-kit.cyclic.app/`;

const apiList = {
  login: `${server}/auth/login`,
  signup:`${server}/auth/signup`,
  allCompanies: `${server}/api/all-companies`,
  likeReview: `${server}/api/like/`,
  reportReview : `${server}/api/report/`,
  individualCompany: `${server}/api/individual-company`,
  companyReviews: `${server}/api/company-reviews`,
  userDetails: `${server}/api/user-details`,
  addReview: `${server}/api/review`,
  allusers: `${server}/api/allusers`,
  addCompany: `${server}/api/addcompany`,
  deleteReview: `${server}/api/deleteReview`,
  adminEmails: `${server}/api/admin-emails`,
  addAdminEmails : `${server}/api/admin-emails/add`
};

export default apiList;
